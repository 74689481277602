<template>
  <div class="pas">
    <div class="person-header">
      <p><strong class="f-18 hei">修改密码</strong></p>
    </div>
    <!-- 快速登录修改密码 -->
    <div class="pas-login">
      <div class="mb-30"></div>
      <!-- 快速登录 -->
      <div class="pas-speediness-box" v-show="showTheDiv.speed">
        <div class="pas-speediness-font">
          <p>
            您当前账号使用了手机号码快速登陆，未设置密码。
          </p>
          <p>
            为保障账户安全，请设置密码。
          </p>
        </div>
        <div class="mb-30"></div>
        <div class="pas-speediness-input">
          <el-form :model="speedinessForm" ref="speedinessForm" label-width="100px" :rules="speedinessFormRules">
            <el-form-item label="请输入密码" prop="password">
              <el-input v-model="speedinessForm.password" :type="speedinessFormFlags==false?'password':'text'"
                        placeholder="请设置密码，6-16位字符" style="width:300px">
                <i slot="suffix" :class="speedinessFormFlags == false ? 'iconfont icon-hide' : 'iconfont icon-show'"
                   @click="changeIconStates()"></i>
              </el-input>
            </el-form-item>
            <el-form-item label="请二次确认" prop="passwordSecond">
              <el-input v-model="speedinessForm.passwordSecond" :type="speedinessFormFlag==false?'password':'text'"
                        placeholder="请再次输入密码" style="width:300px">
                <i slot="suffix" :class="speedinessFormFlag == false ? 'iconfont icon-hide' : 'iconfont icon-show'"
                   @click="changeIconState()"></i>
              </el-input>
            </el-form-item>
            <div class="mb-20"></div>
            <el-button type="primary" size="small" @click="toSubmit"
                       style="width:200px;height:32px;font-size:14px;margin-left:100px">确认提交
            </el-button>
          </el-form>
        </div>
      </div>
      <!-- 手机注册 -->
      <div class="pas-phone-box" v-show="showTheDiv.reg">
        <div class="pas-speediness-font">
          <p>您当前账号使用了手机号码+密码登陆。</p>
        </div>
        <div class="mb-30"></div>
        <div class="phoneForm">
          <el-form :model="phoneForm" ref="phoneForm" label-width="100px" :rules="regRules">
            <el-form-item label="旧密码" prop="passwordOld">
              <el-input v-model="phoneForm.passwordOld" :type="phoneFormFlagOld==false?'password':'text'"
                        placeholder="旧密码" style="width:300px">
                <i slot="suffix" :class="speedinessFormFlag == false ? 'iconfont icon-hide' : 'iconfont icon-show'"
                   @click="changePhoneFormOld()"></i>
              </el-input>
            </el-form-item>
            <el-form-item label="请输入新密码" prop="passwordNew">
              <el-input v-model="phoneForm.passwordNew" :type="phoneFormFlagNew==false?'password':'text'"
                        placeholder="请输入新密码，6-16为字符" style="width:300px">
                <i slot="suffix" :class="speedinessFormFlag == false ? 'iconfont icon-hide' : 'iconfont icon-show'"
                   @click="changePhoneFormNew()"></i>
              </el-input>
            </el-form-item>
            <el-form-item label="请确认新密码" prop="passwordAgain">
              <el-input v-model="phoneForm.passwordAgain" placeholder="请再次输入新密码" style="width:300px"
                        :type="phoneFormFlagAgain==false?'password':'text'">
                <i slot="suffix" :class="speedinessFormFlag == false ? 'iconfont icon-hide' : 'iconfont icon-show'"
                   @click="changePhoneFormAgain()"></i>
              </el-input>
            </el-form-item>
            <div class="mb-20"></div>
            <el-button v-model="newPas" type="primary" size="small"
                       style="width:200px;height:32px;font-size:14px;margin-left:100px"
                       :loading="resetLoding" @click="toReset">确认修改
            </el-button>
          </el-form>
        </div>
      </div>
      <!--  第三方账号登录  -->
      <div class="pas-three-box" v-show="showTheDiv.anonymous">
        <div class="pas-three-box-font">
          <p>您当前使用了第三方账号快速登陆，未设置密码。</p>
          <p>如要使用账号密码登陆，请先绑定手机号码。</p>
        </div>
        <div class="mb-30"></div>
        <el-button type="primary" @click.native="toPage" size="small" style="font-size: 16px;width:200px;height: 36px">绑定手机</el-button>
      </div>

      <!-- 修改成功     -->
      <div class="pas-success-box" v-show="successLogin">
        <p>
          <i class="iconfont icon-success wh-24 g"></i>
          <span class="f-18">您的账户密码修改成功</span>
        </p>
        <div class="mb-20"></div>
        <div class="pas-success-box-font">
          <span>请您牢记新密码。</span>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {requsetPasswordChange} from "@/api/index";
import {getUserInfo} from "@/api/user"

export default {
  data() {
    //快速登录未设置密码
    var validatePass = (rule, value, callback) => {
      if (value == "") {
        callback(new Error('密码不能为空'))
      }else{
        callback()
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.speedinessForm.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    //手机加密码登录
    var validatePasswordOld = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("旧密码不能为空"))
      } else {
        callback()
      }
    }
    var validatePasswordNew = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("您输入的新密码不能为空"))
      } else if (value === this.phoneForm.passwordOld) {
        callback(new Error("您输入的新密码和旧密码一致"))
      } else {
        callback();
      }
    }
    var validatePasswordAgain = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.phoneForm.passwordNew) {
        callback(new Error('两次输入密码不一致!'));
      } else if (value === this.phoneForm.passwordOld) {
        callback(new Error("您再次输入的新密码不能和您的旧密码一致"))
      } else {
        callback();
      }
    }
    return {
      /* 新密码 */
      pas: 1,
      /* 新密码确认 */
      newPas: 1,
      oldPas: 1,
      resetLoding: false,
      // 快速登陆的表单数据
      speedinessForm: {
        password: "",
        passwordSecond: ""
      },
      // 手机号登陆的表单数据
      phoneForm: {
        passwordNew: "",
        passwordAgain: "",
        passwordOld: "",
      },
      //快速登录
      speedinessFormFlags: false,
      speedinessFormFlag: false,

      //手机号码和密码登录
      phoneFormFlagOld: false,
      phoneFormFlagNew: false,
      phoneFormFlagAgain: false,

      //修改成功
      successLogin: false,
      rules: {
        password: [
          {
            validator: validatePass,
            trigger: 'blur',
          }
        ]
      },
      speedinessFormRules: {
        password: [
          {
            validator: validatePass,
            trigger: 'blur'
          }
        ],
        passwordSecond: [
          {
            validator: validatePass2,
            trigger: 'blur'
          }
        ]
      },
      regRules: {
        passwordOld: [
          {
            validator: validatePasswordOld,
            trigger: 'blur',
          }
        ],
        passwordNew: [
          {
            validator: validatePasswordNew,
            trigger: 'blur'
          }
        ],
        passwordAgain: [
          {
            validator: validatePasswordAgain,
            trigger: 'blur'
          }
        ]
      },
      userInfo: {},
      showTheDiv: {
        speed: false,
        reg: false,
        anonymous: false
      }
    };
  },
  mounted() {
    this.loadUserInfo()
  },
  methods: {
    // 显示密码
    showPas() {
      this.pas = 2;
      this.$refs.showPas.type = "text";
    },
    // 隐藏密码
    blankPas() {
      this.pas = 1;
      this.$refs.showPas.type = "password";
    },
    // 显示密码
    NewshowPas() {
      this.newPas = 2;
      this.$refs.showPass.type = "text";
    },
    // 隐藏密码
    NewblankPas() {
      this.newPas = 1;
      this.$refs.showPass.type = "password";
    },
    toReset() {
      this.$refs.phoneForm.validate((success) => {
        if (success) {
          let data = {
            password: this.phoneForm.passwordOld,
            new_password: this.phoneForm.passwordNew,
            new_password_again: this.phoneForm.passwordAgain,
          };
          this.resetPassword( data );
        } else {
          console.log( 'error')
        }
      })
    },
    toSubmit(){
      this.$refs.speedinessForm.validate((success) => {
        if (success) {
          let data = {
            new_password: this.speedinessForm.password,
            new_password_again: this.speedinessForm.passwordSecond,
          };
          this.resetPassword( data );
        } else {
          console.log( 'error')
        }
      })
    },
    toPage(){
      this.$redirectUrl.jumpTo( '/bind/mobile' )
    },
    async loadUserInfo() {
      const res = await getUserInfo()
      if (res.statusCode == 200) {
        this.userInfo = res.data.user
        switch (this.userInfo.type) {
          case 1:// 注册用户
            this.showTheDiv.reg = true
            break;
          case 2:// 匿名用户
            this.showTheDiv.anonymous = true
            break;
          case 3:// 快速登录
            this.showTheDiv.speed = true
            break;
        }
      }
    },
    async resetPassword( data ) {
      const res = await requsetPasswordChange(data);
      if (res.statusCode == 200) {
        this.resetLoding = true;
        this.successLogin = true;
        this.showTheDiv.reg = false
        this.showTheDiv.speed = false
        this.showTheDiv.anonymous = false
      } else {
        this.$notify({
          type: 'error',
          title: '通知',
          message: res.message,
        });
      }
    },
    changeIconStates() {
      this.speedinessFormFlags = !this.speedinessFormFlags
    },
    changeIconState() {
      this.speedinessFormFlag = !this.speedinessFormFlag;
    },
    changePhoneFormOld() {
      this.phoneFormFlagOld = !this.phoneFormFlagOld;
    },
    changePhoneFormNew() {
      this.phoneFormFlagNew = !this.phoneFormFlagNew;
    },
    changePhoneFormAgain() {
      this.phoneFormFlagAgain = !this.phoneFormFlagAgain;
    }
  },
};
</script>

<style lang="less" scoped>
.pas {
  background-color: #fff;
  min-height: 930px;

  .reset-center-width-password {
    font-size: 18px;
    text-align: center;
    margin: 20px 0 40px 0;
  }

  .resetForm {
    width: 300px;
    margin: 0 auto;
    margin-top: 50px;
  }

  .pas-login {
    width: 400px;
    margin: 0 auto;
    min-height: 405px;
    //快速登录
    .pas-speediness-box {
      .pas-speediness-font {
        p {
          line-height: 34px;
        }
      }
    }

    //第三方登录
    .pas-three-box {
      .pas-three-box-font {
        p {
          font-size: 14px;
          line-height: 34px;
        }
      }
    }

    //修改成功
    .pas-success-box {
      p {
        display: flex;
        align-items: center;

        span {
          margin-left: 20px;
        }
      }

      .pas-success-box-font {
        span {
          margin-left: 45px;
          color: #575757;
          font-size: 14px;
        }
      }
    }
  }
}

/deep/ .el-form-item__label {
  color: #575757;
  font-size: 14px;
  text-align: left;
}

/deep/ .el-button {
  border-radius: 0;
}

/deep/ .el-form-item__error {
  margin-top: 0;
}
</style>
